<template>
  <BCard class="c-model-workspace" no-body footer-tag="footer">
    <OCardHeader secondary>{{ $t(`${prefix}.headline`) }}</OCardHeader>
    <BCardBody>
      <OInputSlider :label="`${prefix}.distance-label`" description="" render-label hide-value v-model="distance" />

      <!-- LOCATION -->
      <b-radio class="mb-2" v-model="locationType" :value="WORKSPACE_LOCATIONS.LOCATION">
        {{ $t(`${prefix}.location-types.${WORKSPACE_LOCATIONS.LOCATION}`) }}
      </b-radio>

      <!-- COUNTRY -->
      <b-radio class="mb-2" v-model="locationType" :value="WORKSPACE_LOCATIONS.COUNTRY">
        {{ countryLabel }}
      </b-radio>

      <!-- LOCATIONS -->
      <b-radio class="mb-2" v-model="locationType" :value="WORKSPACE_LOCATIONS.LOCATIONS">
        {{ $t(`${prefix}.location-types.${WORKSPACE_LOCATIONS.LOCATIONS}`) }}
      </b-radio>
      <template v-if="locationType === WORKSPACE_LOCATIONS.LOCATIONS">
        <OInputModelLocation item-format="search" v-model="locations" />
      </template>

      <b-radio v-model="locationType" :value="WORKSPACE_LOCATIONS.WORLDWIDE">
        {{ $t(`${prefix}.location-types.${WORKSPACE_LOCATIONS.WORLDWIDE}`) }}
      </b-radio>
    </BCardBody>
  </BCard>
</template>

<script>
import { computedModel } from 'library/src/utilities/vue/computed-model';
import { WORKSPACE_LOCATIONS } from 'library/src/models/workplace-location.enum';
import OCardHeader from '../../o/card/header';
import OInputOption from '../../o/input/option';
import OInputSlider from '../../o/input/slider';
import OInputModelLocation from '../../o/input/model/location';
import OLayoutGroup from '../../o/layout/group';
import OInputGeolocation from '../../o/input/geolocation';

const prefix = 'c.model-workspace';

export default {
  name: 'c-model-workspace',
  components: {
    OInputGeolocation,
    OLayoutGroup,
    OInputModelLocation,
    OInputSlider,
    OInputOption,
    OCardHeader,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: Object,
    countryCode: String,
  },
  computed: {
    prefix: () => prefix,
    WORKSPACE_LOCATIONS: () => WORKSPACE_LOCATIONS,

    ...computedModel([
      { name: 'locationType', value: WORKSPACE_LOCATIONS.LOCATION },
      { name: 'distance', value: 50 },
      'locations',
    ]),
    translationParams() {
      const { countryCode } = this;
      return {
        country: countryCode ? this.$t(`countries.${countryCode}`) : '',
      };
    },
    countryLabel() {
      const { prefix, translationParams } = this;
      let label = `${prefix}.location-types.${WORKSPACE_LOCATIONS.COUNTRY}`;
      return this.$t(label, translationParams);
    },
  },
};
</script>
