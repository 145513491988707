<template>
  <OInputAbstract class="o-input-model-availability" v-bind="abstractProps">
    <BInputGroup>
      <OInputDate
        v-if="type === TYPES.CUSTOM"
        autofocus
        required
        class="input-item"
        identity="input-model-availability"
        :icon-name="iconName"
        :placeholder="$t(`${prefix}.availability-placeholder`)"
        v-model="date"
        @change="onChangeUpdateModel"
      />
      <template v-else>
        <BInputGroupText v-if="iconName">
          <SOIcon :name="iconName" size="sm" />
        </BInputGroupText>
        <BFormGroup class="input-item">
          <div class="form-control">{{ $t(`${prefix}.yet-label`) }}</div>
        </BFormGroup>
      </template>

      <BDropdown variant="light">
        <template v-slot:button-content></template>
        <BDropdownItem @click="onClickSetType(TYPES.YET)">{{ $t(`${prefix}.yet-label`) }} </BDropdownItem>
        <BDropdownItem @click="onClickSetType(TYPES.CUSTOM)">{{ $t(`${prefix}.from-label`) }} </BDropdownItem>
      </BDropdown>
    </BInputGroup>
  </OInputAbstract>
</template>

<script>
import { valueModelMixinFactory } from 'library/components/src/tools/mixins/value-model.mixin';
import { BInputGroup, BInputGroupText, BFormGroup, BDropdown, BDropdownItem } from 'bootstrap-vue';
import SOIcon from 'library/components/src/components/so/icon';
import OInputAbstract from '../abstract';
import OInputDate from '../date';

const prefix = 'o.input-model-availability';

const TYPES = {
  YET: 'yet',
  CUSTOM: 'custom',
};

const model = {
  date: null,
  type: TYPES.YET,
};

/**
 * @property from
 * @property to
 */
export default {
  name: 'o-input-model-availability',
  mixins: [OInputAbstract, valueModelMixinFactory(model)],
  components: {
    OInputAbstract,
    SOIcon,
    OInputDate,
    BInputGroup,
    BInputGroupText,
    BFormGroup,
    BDropdown,
    BDropdownItem,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    iconName: {
      type: String,
      default: 'calendar',
    },
    prefix: {
      type: String,
      default: prefix,
    },
  },
  computed: {
    TYPES: () => TYPES,
  },
  beforeMount() {
    if (!this.value) {
      this.date = new Date();
      this.type = 'yet';

      //  update params
      this.onChangeUpdateModel();
    }
  },
  methods: {
    onClickSetType(value) {
      this.type = value;

      if (this.type === TYPES.YET) {
        this.date = new Date();
      } else if (!this.date) {
        this.date = new Date();
      }

      //  update params
      this.onChangeUpdateModel();
    },
  },
};
</script>

<style lang="scss">
@import '../../../../component';

.o-input-model-availability {
  .input-item {
    flex-grow: 1;
    margin-bottom: 0;
  }
}
</style>
