<template>
  <BContainer class="v-profile-workspace mt-4">
    <BCardGroup deck>
      <CModelPosition v-model="position">
        <OCardHint slot="footer" has-floated-icon>
          <OContent name="missing-position" as-inline />
        </OCardHint>
      </CModelPosition>
      <BCard no-body>
        <OCardHeader secondary>
          {{ $t(`${prefix}.availability-headline`) }}
        </OCardHeader>
        <BCardBody>
          <OInputModelAvailability v-model="availability" />
        </BCardBody>

        <OCardHeader is-inside-card secondary>
          {{ $t(`${prefix}.salary-headline`) }}*
        </OCardHeader>

        <BCardBody>
          <OInputMoney
            identity="profile-input-current-salary"
            :label="$t('profile.current-salary')"
            :overlay="simplify"
            v-model="currentSalary"
          />
          <OInputMoney
            required
            identity="profile-input-salary-request"
            :label="$t('profile.salary-request')"
            v-model="salaryRequest"
          />
          <OInputMoney
            identity="profile-input-desired-salary"
            :label="$t('profile.desired-salary')"
            :overlay="simplify"
            v-model="desiredSalary"
          />
          <OInputTextarea
            auto-description
            identity="profile-input-salary-note"
            :max="100"
            :label="$t('profile.salary-note')"
            v-model="salaryNote"
          />
        </BCardBody>

        <OCardHeader is-inside-card secondary>
          {{ $t(`${prefix}.desired-headline`) }}
        </OCardHeader>

        <OInputOverlay :show="simplify">
          <BCardBody>
            <OInputOption
              stacked
              as="checkbox"
              identity="profile-option-desired"
              :options="desiredOptions"
              v-model="desired"
            />
          </BCardBody>
        </OInputOverlay>
      </BCard>
    </BCardGroup>

    <BCardGroup deck class="mt-4">
      <CModelWorkspace v-model="workplace" :country-code="countryCode" />

      <!-- exclusion-list -->
      <BCard no-body footer-tag="footer" data-section="exclusion">
        <OCardHeader secondary>
          {{ $t(`${prefix}.exclusion-headline`) }}
        </OCardHeader>
        <OInputOverlay :show="simplify">
          <BCardBody>
            <BFormCheckbox
              data-identity="profile-checkbox-applicant-approval"
              name="applicantApproval"
              :value="true"
              :unchecked-value="false"
              v-model="applicantApproval"
            >
              {{ $t(`${prefix}.applicant-approval-label`) }}
            </BFormCheckbox>
          </BCardBody>
          <BCardBody>
            <OInputModelCompany v-model="forbiddenCompanies" />
          </BCardBody>
          <OCardHint has-floated-icon>
            <OContent name="forbitten-companies-note" as-inline />
          </OCardHint>
        </OInputOverlay>
        <OProfileToolbarEditing
          slot="footer"
          back-route="direct-pv.profile.step-2"
          can-open-preview
          :profile-id="profileId"
          @back="onBack"
          @submit="onSubmit"
        />
      </BCard>
    </BCardGroup>
    <OMarkRequiredLabel />
    <OSticky container-class="container">
      <OLayoutSwitch class="m-2" />
    </OSticky>
  </BContainer>
</template>
<script>
import { BContainer, BCard, BCardBody, BCardGroup, BFormCheckbox } from 'bootstrap-vue';
import { delay } from 'library/src/utilities/delay';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';
import { profileMixin } from '../../../tools/mixins/profile.mixin';
import { pageMixin } from 'library/components/src/plugins/page/mixin/page.mixin';
import { broadcastMixin } from 'library/components/src/tools/mixins/broadcast.mixin';
import { asOptions } from 'library/src/utilities/as-options';
import { onlyProps } from 'library/src/utilities/only-props';
import get from 'lodash.get';

import SOAdd from 'library/components/src/components/so/add';
import SOIcon from 'library/components/src/components/so/icon';
import OInputAbstract from '../../o/input/abstract';
import OInputTextarea from '../../o/input/textarea';
import CModelPosition from '../../c/model/position';
import OCardHeader from '../../o/card/header';
import OInputDate from '../../o/input/date';
import CModelWorkspace from '../../c/model/workspace';
import OContent from '../../o/content';
import OProfileToolbarEditing from '../../o/profile/toolbar/editing';
import OInputModelCompany from '../../o/input/model/company';
import OInputValue from '../../o/input/value';
import OCardHint from '../../o/card/hint';
import OInputMoney from '../../o/input/money';
import OInputOption from '../../o/input/option';
import OLayoutSwitch from '../../o/layout/switch';
import OSticky from '../../o/sticky';
import OInputModelAvailability from '../../o/input/model/availability';
import OInputOverlay from '../../o/input/overlay';
import OMarkRequiredLabel from '../../o/mark/required-label.vue';
import { simplifyMixin } from '../../../tools/mixins/simplify.mixin';

const prefix = 'vw.profile-workspace';

export default {
  mixins: [pageMixin, validationMixin, profileMixin, broadcastMixin, simplifyMixin],
  components: {
    OMarkRequiredLabel,
    OInputOverlay,
    BContainer,
    BCard,
    BCardBody,
    BCardGroup,
    BFormCheckbox,
    OInputModelAvailability,
    OInputOption,
    OInputMoney,
    OInputTextarea,
    OCardHint,
    OInputValue,
    OInputAbstract,
    OInputModelCompany,
    SOAdd,
    SOIcon,
    OContent,
    CModelWorkspace,
    CModelPosition,
    OInputDate,
    OCardHeader,
    OProfileToolbarEditing,
    OLayoutSwitch,
    OSticky,
  },
  data() {
    return {
      showPopover: false,
      internalId: null,
      countryCode: null,

      position: null,
      workplace: null,
      availability: null,
      salaryRequest: null,
      currentSalary: null,
      desiredSalary: null,
      salaryNote: null,
      desired: [],
      // self control for profile
      applicantApproval: false,
      forbiddenCompanies: [],
    };
  },
  computed: {
    prefix: () => prefix,
    model() {
      return onlyProps(this, [
        'position',
        'workplace',
        'availability',
        'salaryRequest',
        'desiredSalary',
        'salaryNote',
        'currentSalary',
        'applicantApproval',
        'forbiddenCompanies',
        'desired',
      ]);
    },
    desiredOptions() {
      return asOptions(this.$t('generic.desired'));
    },
  },

  // validation config
  validations: {
    position: {
      required,
    },
    availability: {
      required,
    },
    workplace: {
      required,
    },
    specialization: {
      required,
      maxLength: maxLength(10),
    },
    skills: {
      required,
      maxLength: maxLength(8),
    },
    salaryRequest: {
      required,
    },
  },

  // hooks
  created() {
    this.$on('profile-loaded', profile => {
      if (profile) {
        this.internalId = profile.internalId;
        Object.assign(this, profile.workspace);
        this.countryCode = get(profile, 'address.countryCode');
      }
    });
    this.$on('profile-not-exists', () => this.profileRoute());
  },

  beforeMount() {
    this.setPageParams({
      sectionGroup: this.$t(`vw.directPv.index.title`),
      sectionTitle: this.$t(`${prefix}.page.title`),
    });
  },

  // methods
  methods: {
    /**
     * @returns {Promise<void>}
     */
    async onSubmit(type) {
      try {
        await delay(250); // to save all model data
        await this.saveProfile();
        this.profileSuccess();
      } catch (e) {
        this.profileError(e);
      } finally {
        this.profileRoute(type, 'direct-pv.profile.step-4');
      }
    },

    async onBack(backRoute) {
      try {
        await this.saveProfile();
      } catch (e) {
        this.profileError(e);
      } finally {
        this.$router.push(backRoute);
      }
    },

    async saveProfile() {
      this.$v.$touch();
      await this.closedBroadcastUp('change-before-submit');
      await delay(250); // to save all model data

      const model = { workspace: this.model };
      this.$logger.info('Submit profile workspace', this.profileId, model);

      await this.$api.profile.saveWorkspace(this.profileId, model);
    },
  },
};
</script>
