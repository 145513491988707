<template>
  <BContainer class="v-profile-assessment mt-4">
    <BCard no-body class='mb-0'>
      <OCardHeader secondary>{{ $t(`${prefix}.headline`) }}*</OCardHeader>
      <BCardBody>
        <OInputTextarea
          v-for="key in sections"
          auto-description
          :key="key"
          v-bind="getSectionProps(key)"
          v-model="assessment[key]"
        />
      </BCardBody>
      <OProfileToolbarEditing
        slot="footer"
        back-route="direct-pv.profile.step-3"
        can-open-preview
        :profile-id="profileId"
        @back="onBack"
        @submit="onSubmit"
      />
    </BCard>
    <OMarkRequiredLabel />
    <OSticky container-class="container">
      <OLayoutSwitch class="m-2" />
    </OSticky>
  </BContainer>
</template>
<script>
import { BContainer, BCard } from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';
import { profileMixin } from '../../../tools/mixins/profile.mixin';
import { pageMixin } from 'library/components/src/plugins/page/mixin/page.mixin';
import { ASSESSMENT_LIST, ASSESSMENT_DEFICITS } from 'library/src/models/assessment.enum';

import OInputAbstract from '../../o/input/abstract';
import OCardHeader from '../../o/card/header';
import CardButtons from '../../o/card/buttons';
import OProfileToolbarEditing from '../../o/profile/toolbar/editing';
import OInputTextarea from '../../o/input/textarea';
import OLayoutSwitch from '../../o/layout/switch';
import OSticky from '../../o/sticky';
import OMarkRequiredLabel from '../../o/mark/required-label.vue';
import { simplifyMixin } from '../../../tools/mixins/simplify.mixin';

const prefix = 'vw.profile-assessment';
const MIN_LENGTH = 12;
const MAX_LENGTH = 1000;

export default {
  mixins: [pageMixin, validationMixin, profileMixin, simplifyMixin],
  components: {
    OMarkRequiredLabel,
    BContainer,
    BCard,
    OInputTextarea,
    OCardHeader,
    OInputAbstract,
    CardButtons,
    OProfileToolbarEditing,
    OLayoutSwitch,
    OSticky,
  },
  data() {
    // create reactive props
    const assessment = {};

    ASSESSMENT_LIST.forEach(key => {
      assessment[key] = '';
    });

    return { assessment };
  },
  computed: {
    prefix: () => prefix,
    sections: () => ASSESSMENT_LIST,
  },
  validations: {},

  // hooks
  created() {
    this.$on('profile-loaded', profile => {
      if (profile && profile.assessment) {
        Object.assign(this.assessment, profile.assessment);
      }
    });
    this.$on('profile-not-exists', () => this.profileRoute());
  },

  beforeMount() {
    this.setPageParams({
      sectionGroup: this.$t(`vw.directPv.index.title`),
      sectionTitle: this.$t(`${prefix}.page.title`),
    });
  },

  // methods
  methods: {
    getSectionProps(key) {
      const lowerKey = key.toLowerCase();

      const props = {
        label: this.$t(`generic.assessment.${lowerKey}`),
        identity: `assessment-input-${lowerKey}`,
        required: true,
        min: MIN_LENGTH,
        max: MAX_LENGTH,
      };

      if (key === ASSESSMENT_DEFICITS) {
        Object.assign(props, {
          required: false,
          min: 0,
          overlay: this.simplify,
        });
      }

      return props;
    },
    /**
     * @returns {Promise<void>}
     */
    async onSubmit(type) {
      try {
        await this.saveProfile();
        this.profileSuccess();
      } catch (e) {
        this.profileError(e);
      } finally {
        // allow invalid inputs but continue
        this.profileRoute(type, 'direct-pv.profile.step-5');
      }
    },

    async onBack(backRoute) {
      try {
        await this.saveProfile();
      } catch (e) {
        this.profileError(e);
      } finally {
        this.$router.push(backRoute);
      }
    },

    async saveProfile() {
      this.$v.$touch();

      const model = { ...this.assessment };
      this.$logger.info('Submit profile assessment', this.profileId);
      await this.$api.profile.saveAssessment(this.profileId, model);
    },
  },
};
</script>
