<template>
  <OInputAbstract
    class="o-input-model-company"
    v-bind="abstractProps"
    :label="$t(`${prefix}.label`)"
    :description="$t(`${prefix}.description`)"
  >
    <OInputTagsAbstract
      create-new-keyword
      :placeholder="$t(`${prefix}.placeholder`)"
      :create-tag="createTag"
      :load-options="loadOptions"
      v-model="model"
    />
  </OInputAbstract>
</template>

<script>
import OInputAbstract from '../abstract';
import OInputTagsAbstract from '../tags/abstract';
import { languageMixin } from 'library/components/src/tools/mixins/language.mixin';

const prefix = 'o.input-model-company';

export default {
  name: 'o-input-model-company',
  mixins: [OInputAbstract, languageMixin],
  components: {
    OInputAbstract,
    OInputTagsAbstract,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: Array,
  },
  computed: {
    prefix: () => prefix,
    model: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      },
    },
  },

  methods: {
    /**
     * create new tag by input
     **/
    createTag(input) {
      this.$logger.info('Create new company %s', input);

      return (
        this.$api.company
          // create new company
          .createCompany(input)
          .catch(this.$logger.error)
      );
    },

    /**
     * fetch new list for options
     */
    loadOptions(input) {
      return this.$api.company.autocomplete(input);
    },
  },
};
</script>
