<template>
  <OInputAbstract class="o-input-model-location" v-bind="abstractProps">
    <SOTags v-model="values" is-removable />
    <OInputGeolocation
      class="value-input"
      label="false"
      v-model="input"
      v-bind="geolocationProps"
      @selected="onClickInsert(input)"
      @blur="onClickInsert(input)"
    />
  </OInputAbstract>
</template>

<script>
import { BInputGroup } from 'bootstrap-vue';
import { LOCATION_TYPES, LOCATION_TYPE_LIST } from 'library/src/models/location-type.enum';
import { assign } from 'library/src/utilities/assign';
import { languageMixin } from 'library/components/src/tools/mixins/language.mixin';

import SOTags from 'library/components/src/components/so/tags';
import OInputAbstract from '../abstract';
import OInputGeolocation from '../geolocation';

const ITEM_FORMAT_ADDRESS = 'address';
const ITEM_FORMAT_COORDS = 'coords';
const ITEM_FORMAT_SEARCH = 'search';
const ITEM_FORMATS = [ITEM_FORMAT_ADDRESS, ITEM_FORMAT_COORDS, ITEM_FORMAT_SEARCH];

const prefix = 'o.input-model-location';

export default {
  name: 'o-input-model-location',
  mixins: [OInputAbstract, languageMixin],
  components: { BInputGroup, OInputGeolocation, OInputAbstract, SOTags },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      input: null,
    };
  },
  props: {
    value: {},
    locationType: {
      type: String,
      default: LOCATION_TYPES.ALL,
      validate: v => LOCATION_TYPE_LIST.includes(v),
    },
    locationLanguage: {
      type: String,
      default: 'de',
    },
    locationCountryCode: String,

    /**
     * define item format to store special data for each entry
     **/
    itemFormat: {
      type: String,
      default: ITEM_FORMAT_ADDRESS,
      validate: v => ITEM_FORMATS.includes(v),
    },
  },
  computed: {
    prefix: () => prefix,
    model: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      },
    },
    values: {
      get() {
        if (Array.isArray(this.model)) {
          return this.model;
        }
        return [];
      },
      set(value) {
        this.model = value;
      },
    },
    geolocationProps() {
      const props = {
        filterBy: this.locationType,
        language: this.locationLanguage,
        countryCode: this.locationCountryCode,
        placeholder: this.$t(`${prefix}.placeholder`),
        noFieldContainer: true,
        noStatusIcon: true,
      };

      // assign only valid
      assign(props, {
        input: this.inputLabel,
        placeholder: this.inputPlaceholder,
      });

      return props;
    },
  },
  methods: {
    /**
     * insert new ITEM
     *
     * @param value
     */
    onClickInsert(value) {
      const { model } = this;

      let item = {
        label: value.label,
      };

      // coordinate data
      switch (this.itemFormat) {
        case ITEM_FORMAT_COORDS:
          Object.assign(item, value.location);
          break;
        case ITEM_FORMAT_SEARCH:
          Object.assign(item, {
            ...value.address,
            ...value.location,
          });
          break;
        // ITEM_FORMAT_ADDRESS
        default:
          Object.assign(item, value.address);
      }

      const items = Array.isArray(model) ? model : [];
      items.push(item);
      this.model = items;

      this.$nextTick(() => (this.input = null));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../component';

.o-input-select-location {
  &:last-child {
    margin-bottom: 0;
  }
}

.value-input {
  flex: auto;
}
</style>
