<template>
  <div class="c-model-career-education group-container as-parent">
    <OInputGroupHeader no-index-label :index="index" :headline="headline" @remove="$emit('remove', { index })" />
    <OInputTextarea
      required
      autofocus
      auto-description
      :max="500"
      :rows="10"
      v-model="description"
      @change="onChangeUpdateModel"
    />
    <OInputModelDatecareer :label="$t('fields.date-range')" v-model="date" @change="onChangeUpdateModel" />
  </div>
</template>

<script>
import { valueModelMixinFactory } from 'library/components/src/tools/mixins/value-model.mixin';
import OInputGroupHeader from '../../../o/input/group/header';
import OInputModelDatecareer from '../../../o/input/model/datecareer';
import OInputTextarea from '../../../o/input/textarea';

const prefix = 'c.model-career-education';

const model = {
  description: null,
  date: null,
};

/**
 * @property description
 * @property date
 */
export default {
  name: 'c-model-career-education',
  mixins: [valueModelMixinFactory(model)],
  components: {
    OInputTextarea,
    OInputModelDatecareer,
    OInputGroupHeader,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    prefix: () => prefix,
    headline() {
      return this.$t(`${prefix}.headline-index`, {
        index: this.index + 1,
      });
    },
  },
};
</script>
