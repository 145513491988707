<template>
  <div class="c profile-calculator">
    <template v-if="isValidCommission">
      <h6 class="mt-0 font-weight-bold">
        {{ $t(`${prefix}.employer-headline`) }}
      </h6>
      <BRow>
        <BCol md="5"> {{ $t(`${prefix}.you-receive-as`) }} : </BCol>
        <BCol md="5">
          {{ value('employer', 'broker') }}
        </BCol>
      </BRow>
      <BRow>
        <BCol md="5">{{ $t(`${prefix}.pf-receive`) }}:</BCol>
        <BCol md="5">
          {{ value('employer', 'portal') }}
        </BCol>
      </BRow>

      <hr />

      <h6 class="mt-3 font-weight-bold">
        {{ $t(`${prefix}.recruiter-headline`) }}
      </h6>
      <BRow>
        <BCol md="5">{{ $t(`${prefix}.you-receive-as`) }}:</BCol>
        <BCol md="5">
          {{ value('recruiter', 'broker') }}
        </BCol>
      </BRow>
      <BRow>
        <BCol md="5">{{ $t(`${prefix}.gets-recruiter`) }}: </BCol>
        <BCol md="5">
          {{ value('recruiter', 'partner') }}
        </BCol>
      </BRow>
      <BRow>
        <BCol md="5">{{ $t(`${prefix}.pf-receive`) }}:</BCol>
        <BCol md="5">
          {{ value('recruiter', 'portal') }}
        </BCol>
      </BRow>
    </template>
    <template v-else>
      <p class="font-weight-bold text-danger">
        {{ $t(`${prefix}.headline-commission-invalid`) }}
      </p>
    </template>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';

export default {
  name: 'o-profile-calculator',
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      calculated: null,
    };
  },
  props: {
    commission: Number,
  },
  computed: {
    prefix: () => 'profile.calculator',
    isValidCommission() {
      return this.commission > 0;
    },
  },
  watch: {
    commission(value) {
      this.calculated = null;

      if (value > 0) {
        this.updateCalculation();
      }
    },
  },
  beforeMount() {
    this.updateCalculation();
  },
  methods: {
    value(group, key) {
      const { prefix, calculated } = this;
      if (calculated) {
        const value = calculated[group][key];
        return this.$t(`${prefix}.annual-gross-amount`, { value });
      }
    },
    async updateCalculation() {
      try {
        if (this.isValidCommission) {
          const { commission } = this;
          this.calculated = await this.$api.profile.calculate(commission);
        }
      } catch (e) {
        this.$logger.warn(e);
      }
    },
  },
};
</script>
