<template>
  <BContainer class='v-profile-career mt-4'>
    <BRow>
      <BCol lg='6'>
        <!--professional-background -->
        <BCard no-body data-section='professional-background' class='h-100'>
          <OCardHeader secondary>{{ $t(`${prefix}.professional-career-headline`) }}*</OCardHeader>
          <BCardBody>
            <OInputOverlay
              v-for='(career, index) in professionalCareer'
              :key='`career-${index}`'
              :show="simplify && index > 0"
            >
              <CModelCareerProfessional
                class='as-parent'
                :index='index'
                v-model='professionalCareer[index]'
                @remove='onRemoveProfessionalCareer'
              />
            </OInputOverlay>
            <OInputOverlay :show="simplify">
              <SOAdd
                data-identity='profile-button-professional-background'
                :label='$t(`${prefix}.add-professional-career-label`)'
                @click='onAddProfessionalCareer'
              />
            </OInputOverlay>
            <SOAdd
              v-if='simplify && !professionalCareer.length'
              class='mt-2'
              label='Aktuelles Unternehmen oder Status hinzufügen'
              @click='onAddProfessionalCareer'
            />
          </BCardBody>
          <OCardHint v-if='hintReportLabel' :variant='hintReportClassName'>
            {{ hintReportLabel }}
          </OCardHint>
        </BCard>
      </BCol>

      <!-- right side -->
      <BCol lg='6'>
        <!-- further-education -->
        <BCard no-body data-section='further-education' class='h-100'>
          <OCardHeader secondary>{{ $t(`${prefix}.further-education-headline`) }}</OCardHeader>
          <BCardBody>
            <OInputOverlay :show="simplify">
              <CModelCareerEducation
                v-for='(item, index) in furtherEducation'
                :key='`further-education-${index}`'
                :index='index'
                v-model='furtherEducation[index]'
                @remove='onRemoveFurtherEducation'
              />
              <SOAdd data-identity='profile-button-further-education' @click='onAddFurtherEducation' />
            </OInputOverlay>
          </BCardBody>

          <OProfileToolbarEditing
            slot='footer'
            back-route='direct-pv.profile.step-5'
            can-open-preview
            :profile-id='profileId'
            @back='onBack'
            @submit='onSubmit'
          />
        </BCard>
      </BCol>
    </BRow>
    <ODialogCompletion v-model='isCompleted' :internal-id='internalId' />
    <OMarkRequiredLabel />
    <OSticky container-class="container">
      <OLayoutSwitch class="m-2" />
    </OSticky>
  </BContainer>
</template>

<script>
import { delay } from 'library/src/utilities/delay';
import { timestampReport } from 'library/src/utilities/reporter/timestamp-report';
import { validationMixin } from 'vuelidate';
import { profileMixin } from '../../../tools/mixins/profile.mixin';
import { pageMixin } from 'library/components/src/plugins/page/mixin/page.mixin';

import { BCard, BCardBody, BCol, BContainer, BRow } from 'bootstrap-vue';
import SOAdd from 'library/components/src/components/so/add';
import OCardHeader from '../../o/card/header';
import ODialogCompletion from '../../o/dialog/completion';
import OProfileToolbarEditing from '../../o/profile/toolbar/editing';
import CModelCareerProfessional from '../../c/model/career/professional';
import CModelCareerEducation from '../../c/model/career/education';
import OCardHint from '../../o/card/hint';
import OLayoutSwitch from '../../o/layout/switch';
import OSticky from '../../o/sticky';
import OInputOverlay from '../../o/input/overlay';
import OMarkRequiredLabel from '../../o/mark/required-label.vue';
import { simplifyMixin } from '../../../tools/mixins/simplify.mixin';

const prefix = 'vw.profile-career';

export default {
  mixins: [pageMixin, validationMixin, profileMixin, simplifyMixin],
  components: {
    OMarkRequiredLabel,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardBody,
    CModelCareerEducation,
    CModelCareerProfessional,
    SOAdd,
    ODialogCompletion,
    OCardHeader,
    OProfileToolbarEditing,
    OCardHint,
    OInputOverlay,
    OSticky,
    OLayoutSwitch,
  },
  data() {
    return {
      isCompleted: false,
      eventsOfDefault: [],
      internalId: null,
      professionalCareer: [],
      furtherEducation: [],
      errorReport: { isValid: true },
    };
  },

  computed: {
    prefix: () => prefix,
    hintReportClassName() {
      const { isValid } = this.errorReport;
      return isValid ? 'hint' : 'error';
    },
    hintReportLabel() {
      const { isValid } = this.errorReport;
      const path = `${prefix}.report-label`;
      const count = this.professionalCareer.length;

      if (count === 0) {
        return this.$t(`${path}.empty`);
      }

      if (!isValid) {
        return this.$t(`${path}.invalid`);
      }

      if (count > 1) {
        return this.$t(`${path}.valid`, { count });
      }
    },
  },

  watch: {
    professionalCareer: {
      deep: true,
      handler(v) {
        timestampReport(v).then(report => (this.errorReport = report));
      },
    },
  },

  // for validation support
  validations: {},

  // hooks
  created() {
    this.$on('profile-loaded', profile => {
      if (profile) {
        this.internalId = profile.internalId;
        Object.assign(this, profile.career);
      }
    });
    this.$on('profile-not-exists', () => this.profileRoute());
  },

  beforeMount() {
    this.setPageParams({
      sectionGroup: this.$t(`vw.directPv.index.title`),
      sectionTitle: this.$t(`${prefix}.page.title`),
    });
  },

  // methods
  methods: {
    /**
     * submit form
     * @returns {Promise<void>}
     */
    async onSubmit(type) {
      try {
        await this.saveProfile();
      } catch (e) {
        this.profileError(e);
      } finally {
        if (typeof type === 'string') {
          this.profileRoute(type);
        } else {
          this.isCompleted = true;
        }
      }
    },

    async onBack(backRoute) {
      try {
        await this.saveProfile();
      } catch (e) {
        this.profileError(e);
      } finally {
        this.$router.push(backRoute);
      }
    },

    async saveProfile() {
      this.$v.$touch();
      await delay(250); // to save all model data

      const model = {
        professionalCareer: this.professionalCareer,
        furtherEducation: this.furtherEducation,
      };

      this.$logger.info('Submit profile career', this.profileId, model);
      await this.$api.profile.saveCareer(this.profileId, model);
    },

    /**
     * ProfessionalCareer
     */
    onAddProfessionalCareer() {
      this.professionalCareer.push({
        job: [],
      });
    },
    onRemoveProfessionalCareer({ index }) {
      this.professionalCareer.splice(index, 1);
    },

    /**
     * FurtherEducation
     */
    onAddFurtherEducation() {
      this.furtherEducation.push({});
    },
    onRemoveFurtherEducation({ index }) {
      this.furtherEducation.splice(index, 1);
    },
  },
};
</script>
