<template>
  <ODialog v-model="isDisplayed" :headline="$t('profile.saved-successfully')" hide-close-button>
    <div class="text-center">
      <p class="mb-1">
        {{ $t('profile.successfully-imported') }}
      </p>
      <p class="mb-1 font-weight-bolder">{{ internalId }}</p>
      <p class="mb-1">
        {{ $t('profile.profile-is-inside') }}
      </p>
    </div>
    <OProfileDialogToolbarNext slot="toolbar" v-model="isDisplayed" hide-cancel />
  </ODialog>
</template>

<script>
import SOIcon from 'library/components/src/components/so/icon';
import ODialog from '../dialog';
import OProfileDialogToolbarNext from '../profile/dialog/toolbar-next';

export default {
  name: 'o-dialog-completion',
  components: { OProfileDialogToolbarNext, ODialog, SOIcon },
  model: {
    prop: 'show',
    event: 'change',
  },
  props: {
    show: Boolean,
    internalId: {
      required: true,
    },
  },
  computed: {
    isDisplayed: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit('change', newValue);
      },
    },
  },
};
</script>
