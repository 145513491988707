<template>
  <BCardBody :class="className">
    <BRow class="innerRow">
      <BCol sm="shrink">
        <SOIcon name="exclamation-triangle" size="sm" class="icon" />
      </BCol>
      <BCol>
        <slot />
      </BCol>
    </BRow>
  </BCardBody>
</template>
<script>
import { BCardBody, BCol, BRow } from 'bootstrap-vue';
import SOIcon from 'library/components/src/components/so/icon';

const prefix = 'o.card-hint';

export default {
  name: 'o-card-hint',
  components: { BRow, BCol, BCardBody, SOIcon },
  props: {
    variant: {
      default: 'hint',
    },
    iconName: {
      default: 'exclamation-triangle',
    },
  },
  computed: {
    className() {
      return `o-card-hint color-set-${this.variant}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.o-card-hint {
  flex-grow: 0;
}

.innerRow {
  margin: 0;
}

.icon {
  float: left;
  margin: {
    right: 4px;
    top: 4px;
  }
}
</style>
