<template>
  <BRow :class="className">
    <BCol md="12" v-if="error">
      <SOErrorMessage :error="error" :translation-key="errorTranslationKey" use-validation />
    </BCol>
    <BCol md="12" class="">
      <div class="text-right button-container">
        <slot name="buttons" />
        <BButton v-if="backTo || disableBack" variant="dark" :to="backTo" :disabled="disableBack">
          {{ backLabel }}
        </BButton>
        <BButton
          data-identity="card-buttons-submit"
          variant="primary"
          :type="submitType"
          :disabled="disableSaveContinue"
          :to="to"
          @click="$emit('submit', $event)"
        >
          {{ submitLabel }}
        </BButton>
      </div>
    </BCol>
  </BRow>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue';
import SOErrorMessage from 'library/components/src/components/so/error-message';

const prefix = 'o.card-buttons';

export default {
  name: 'o-card-buttons',
  components: {
    SOErrorMessage,
    BRow,
    BCol,
    BButton,
  },
  props: {
    error: Error,
    errorTranslationKey: String,
    backTo: {
      type: [Object, String],
      default: null,
    },
    disableSaveContinue: Boolean,
    disableBack: Boolean,
    submitType: {
      type: String,
      default: 'submit',
    },
    to: {
      type: [Object, String],
      default: null,
    },
    backLabel: {
      type: String,
      default() {
        return this.$t(`${prefix}.back-button`);
      },
    },
    submitLabel: {
      type: String,
      default() {
        return this.$t(`${prefix}.submit-button`);
      },
    },
  },
  computed: {
    className() {
      return ['o-card-buttons'];
    },
  },
};
</script>
