<template>
  <div class="c-model-career-school group-container as-parent">
    <OInputGroupHeader no-index-label :index="index" :headline="headline" @remove="$emit('remove', { index })" />
    <OInputValue
      autofocus
      :placeholder="$t(`${prefix}.name-placeholder`)"
      :description="$t(`${prefix}.name-description`)"
      v-model="name"
      @change="onChangeUpdateModel"
    />
    <OInputModelDatecareer
      :label="$t('fields.date-range')"
      :description="$t('fields.date-range-description')"
      v-model="date"
      @change="onChangeUpdateModel"
    />

    <b-form-checkbox class="mt-2 mb-3" v-model="completed" @change="onChangeUpdateModel">
      {{ $t(`${prefix}.completed-label`) }}
    </b-form-checkbox>

    <OInputOption
      v-if="completed"
      as="select"
      :label="$t('generic.school-degree.label')"
      :options="this.$t('generic.school-degree.enum') | toOptions"
      v-model="typeOfDegree"
      @change="onChangeUpdateModel"
    />
  </div>
</template>

<script>
import { toOptions } from 'library/components/src/tools/filters/to-options';
import { valueModelMixinFactory } from 'library/components/src/tools/mixins/value-model.mixin';
import OInputGroupHeader from '../../../o/input/group/header';
import OInputValue from '../../../o/input/value';
import OInputOption from '../../../o/input/option';
import OInputModelDatecareer from '../../../o/input/model/datecareer';

const prefix = 'c.model-career-school';

const model = {
  name: null,
  completed: null,
  date: null,
  typeOfDegree: null,
};

/**
 * @property name
 * @property completed
 * @property typeOfDegree
 */
export default {
  name: 'c-model-career-school',
  mixins: [valueModelMixinFactory(model)],
  components: {
    OInputModelDatecareer,
    OInputOption,
    OInputValue,
    OInputGroupHeader,
  },
  filters: { toOptions },
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    prefix: () => prefix,
    headline() {
      const { index, name } = this;

      const key = name ? 'headline-index-has-title' : 'headline-index';
      return this.$t(`${prefix}.${key}`, {
        index: index + 1,
        name,
      });
    },
  },
};
</script>
