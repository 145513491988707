<template>
  <OInputAbstract :class="className" v-bind="abstractInputProps">
    <BInputGroup>
      <BFormInput
        type="number"
        :data-identity="identity"
        :state="errorState"
        v-bind="inputProps"
        v-model.number.trim="model"
      />
      <OMarkRequired v-if="required" />
      <BInputGroupText>
        {{ $t(`${prefix}.append-label`) }}
      </BInputGroupText>
    </BInputGroup>
  </OInputAbstract>
</template>

<script>
import { BInputGroup, BFormInput, BInputGroupText } from 'bootstrap-vue';
import OInputAbstract from './abstract';
import SOIcon from 'library/components/src/components/so/icon';
import OMarkRequired from '../mark/required';

const prefix = 'o.input-experience';

export default {
  name: 'o-input-experience',
  mixins: [OInputAbstract],
  components: {
    BInputGroup,
    BFormInput,
    BInputGroupText,
    OMarkRequired,
    SOIcon,
    OInputAbstract,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    identity: String,
    required: Boolean,
    value: {},
  },
  computed: {
    prefix: () => prefix,
    className() {
      return [
        'o-input-experience',
        {
          'is-required': this.required,
        },
      ];
    },
    labelClassName() {
      return [
        'font-weight-bold',
        {
          'text-danger': this.error,
        },
      ];
    },
    model: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('change', newValue);
      },
    },
    abstractInputProps() {
      return {
        ...this.abstractProps,
        label: this.$t(`${prefix}.label`),
        description: this.$t(`${prefix}.description`),
      };
    },
    inputState() {
      if (!this.inputValue && this.required) {
        return {
          type: 'is-danger',
          message: this.$i18n.t('input-required'),
        };
      }
      return {
        type: 'is-primary',
        message: '',
      };
    },
    inputProps() {
      const attrs = { ...this.$attrs };

      attrs.class = {
        'has-required-marked': this.required,
        'none-appearance': true,
      };

      return attrs;
    },
  },
};
</script>
