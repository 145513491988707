import { localStorage } from '../../services';
import { mapMutations } from 'vuex';

export const simplifyMixin = {
  computed: {
    simplify: {
      get() {
        return this.$store.state.simplify;
      },
      set(v) {
        this.$store.commit('setSimplify', v, { root: true });
      },
    },
  },
};
