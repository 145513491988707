<template>
  <div :class="className">
    <div v-if="show" class="clip-area" :class="{'is-flipped': flipOffset}">
      <iframe :src="clipUrl" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
        :title="title"></iframe>
    </div>
    <BButton class="clip-button" :class="`on-${show ? 'active' : state}`" variant="primary" @click="showClip">
      <SOIcon :name="show ? 'times' : 'video'" :size="[floating ? 'xl' : 'sm']" />
    </BButton>
  </div>
</template>

<script>

import { BButton } from 'bootstrap-vue';
import SOIcon from 'library/components/src/components/so/icon';

export default {
  name: 'c-trainclip',
  components: {
    BButton,
    SOIcon
  },
  props: {
    title: String,
    url: String,
    attached: Boolean,
    floating: Boolean,
    buttomEdge: Boolean,
  },
  data: () => {
    return {
      show: false,
      state: 'paused',
      flipOffset: false,
    };
  },
  computed: {
    className() {
      return [
        'c-trainclip',
        this.floating ? 'is-floating' : 'is-inline',
        this.attached ? 'is-attached' : '',
        this.buttomEdge ? 'is-bottom-edge' : '',
      ];
    },
    clipUrl() {
      const url = new URL(this.url);
      url.searchParams.set('autoplay', '1');
      url.searchParams.set('autopause', '1');
      url.searchParams.set('app_id', '58479');
      url.searchParams.set('badge', '0');
      return `${url}`;
    },
  },
  mounted() {
    this.state = 'enter';

    setTimeout(() => {
      this.state = 'wait';
    }, 1000);
  },
  methods: {
    showClip() {
      this.offset = 0;
      this.show = !this.show;
      // update offset position
      const bounce = this.$el.getBoundingClientRect();
      const screenWidth = window.innerWidth;
      const clipSize = 300;
      this.flipOffset = bounce.x + clipSize > screenWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../component';

$offset: 1rem;
$size: 26px;
$floatingSize: 50px;

.c-trainclip {
  height: $size;
  display: block;
}

%aligned {
  position: fixed;
  right: $offset;
  bottom: $offset;
  z-index: $index-overlay;
}

.clip-area {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  max-width: 90vw;
  width: 400px;
  height: 320px;
  margin: 10px;
  background-color: white;
  z-index: $index-overlay;

  > iframe {
    width: 100%;
    height: 100%;
  }
}

.clip-button {
  border-radius: 100%;
  width: $size;
  height: $size;
  padding: 0;
  opacity: 0;
  background-color: $color-brand;
  border: none;
  color: black;
  box-shadow: $drop-shadow;
  transition: transform 100ms ease-in-out;

  &:active,
  &:focus {
    outline: none;
    box-shadow: inherit;
    color: black;
    background-color: $color-brand;
  }

  &.on-enter {
    animation: popin 100ms ease-out;
    animation-play-state: running;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-delay: .5s;
  }

  &.on-wait {
    animation: pulse 2s ease-in-out;
    animation-iteration-count: infinite;
  }

  &.on-active {
    opacity: 1;
    transform: translate(20%, 20%) scale(.7);
  }
}

.is-floating {
  .clip-area {
    @extend %aligned;
    position: fixed;
  }

  .clip-button {
    @extend %aligned;
    height: $floatingSize;
    width: $floatingSize;
  }
}

.is-inline {
  display: inline-block;
  position: relative;

  .clip-area {
    position: absolute;
    left: $size / 2;
    top: $size / 2;

    &.is-flipped {
      left: auto;
      right: 0;
    }
  }

  .clip-button {
    position: relative;
    z-index: $index-overlay;
  }
}

.is-attached {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

.is-bottom-edge {
  .clip-area {
    top: auto;
    bottom: 0;
  }
}

@keyframes popin {
  0% {
    opacity: 1;
    transform: scale(0);
  }

  70%,
  100% {
    opacity: 1;
    transform: scale(1);
  }

  80% {
    transform: scale(1.2);
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }
}</style>
