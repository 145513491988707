<template>
  <div class="c-model-career-job group-container">
    <OInputGroupHeader no-index-label :index="index" :headline="headline" @remove="$emit('remove', { index })" />
    <OInputValue
      required
      autofocus
      identity="career-job-input-position"
      :placeholder="$t(`${prefix}.position-placeholder`)"
      v-model="title"
      @change="onChangeUpdateModel"
    />
    <OInputModelDatecareer
      :label="$t('fields.date-range')"
      :description="$t('fields.date-range-description')"
      v-model="date"
      @change="onChangeUpdateModel"
    />
    <OInputTagsInput
      :label="$t(`${prefix}.practice-label`)"
      :placeholder="$t(`${prefix}.practice-placeholder`)"
      tag-variant="flow-row"
      v-model="practice"
      @change="onChangeUpdateModel"
    />
  </div>
</template>

<script>
import { valueModelMixinFactory } from 'library/components/src/tools/mixins/value-model.mixin';
import OInputGroupHeader from '../../../o/input/group/header';
import OInputValue from '../../../o/input/value';
import OInputTagsInput from '../../../o/input/tags/input';
import OInputModelDatecareer from '../../../o/input/model/datecareer';

const prefix = 'c.model-career-job';

const model = {
  title: null,
  date: null,
  practice: null,
};

/**
 * @property title
 * @property date
 * @property pactice
 */
export default {
  name: 'c-model-career-job',
  mixins: [valueModelMixinFactory(model)],
  components: {
    OInputModelDatecareer,
    OInputTagsInput,
    OInputGroupHeader,
    OInputValue,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    prefix: () => prefix,
    headline() {
      const { index, title } = this;

      const key = title ? 'headline-index-has-title' : 'headline-index';
      return this.$t(`${prefix}.${key}`, {
        index: index + 1,
        title,
      });
    },
  },
};
</script>
