<template>
  <component :is="tagName" :class="className">
    <h5 class="mb-0 mr-auto font-weight-bold"><slot /></h5>
    <div v-if="tooltip" :title="tooltip" class="header-tooltip">
      <SOIcon variant="outline" name="question-circle" />
    </div>
    <slot name="append" />
  </component>
</template>

<script>
import SOIcon from 'library/components/src/components/so/icon';

export default {
  name: 'o-card-header',
  components: {
    SOIcon,
  },
  props: {
    tooltip: String,
    isInsideCard: Boolean,
    secondary: Boolean,
  },
  computed: {
    className() {
      return [
        'o-card-header d-flex',
        {
          'card-header': this.secondary || this.isInsideCard,
          'text-white bg-secondary': this.secondary,
        },
      ];
    },
    tagName() {
      return this.isInsideCard ? 'header' : 'div';
    },
  },
};
</script>

<style lang="scss">
.o-card-header {
  .header-tooltip {
    cursor: help;
    opacity: 0.4;

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
