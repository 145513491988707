<template>
  <header class="o-input-group-header d-flex mb-2">
    <span class="group-container-marker"></span>
    <BInputGroupText class="label font-weight-bold">
      <span v-if="hasIndex" class="index-label">{{ indexLabel }}</span>
      <span v-if="headline">{{ headline }}</span>
    </BInputGroupText>
    <BButton variant="danger" @click="$emit('remove', index)">
      <SOIcon name="trash" size="sm" />
    </BButton>
  </header>
</template>

<script>
import { BButton, BInputGroupText } from 'bootstrap-vue';
import SOIcon from 'library/components/src/components/so/icon';

export default {
  name: 'o-input-group-header',
  components: {
    BButton,
    BInputGroupText,
    SOIcon,
  },
  props: {
    index: Number,
    headline: String,
    noIndexLabel: Boolean,
  },
  computed: {
    hasIndex() {
      return this.noIndexLabel ? false : this.index > -1;
    },
    indexLabel() {
      return `# ${this.index + 1}`;
    },
  },
};
</script>
<style lang="scss">
.o-input-group-header {
  > .label {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
