<template>
  <div class="c-profile-dialog-toolbar-next text-center w-100">
    <p class="font-weight-bold">
      {{ $t(`${prefix}.what-next`) }}
    </p>
    <div class="button-container">
      <BButton
        v-if="!hideCancel"
        data-identity="profile-dialog-toolbar-next-button-cancel"
        variant="dark"
        size="sm"
        @click="onClickCancel"
      >
        <SOIcon name="arrow-left" size="sm" as-label-icon />
        {{ $t(`${prefix}.cancel-label`) }}
      </BButton>

      <BButton
        size="sm"
        data-identity="profile-dialog-toolbar-next-button-to-profiles"
        variant="dark"
        @click="onClickToProfiles"
      >
        <SOIcon name="eye" size="sm" as-label-icon />
        {{ $t(`${prefix}.to-profiles`) }}
      </BButton>

      <BButton
        size="sm"
        data-identity="profile-dialog-toolbar-next-button-create-profile"
        variant="primary"
        @click="onClickCreateNewProfile"
      >
        <SOIcon name="user-plus" size="sm" as-label-icon />
        {{ $t(`${prefix}.create-profile`) }}
      </BButton>

      <BButton
        v-if="!hidePreview"
        data-identity="profile-dialog-toolbar-next-button-preview"
        size="sm"
        variant="primary"
        @click="profileRoute('preview')"
      >
        <SOIcon name="eye" size="sm" as-label-icon />
        {{ $t(`${prefix}.preview`) }}
      </BButton>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import { ownerRouteId } from '@/src/sitemap';
import { profileMixin } from '../../../../tools/mixins/profile.mixin';
import SOIcon from 'library/components/src/components/so/icon';

const prefix = 'o.profile-dialog-toolbar-next';

export default {
  name: 'o-profile-dialog-toolbar-next',
  mixins: [profileMixin],
  components: { SOIcon, BButton },
  model: {
    prop: 'show',
    event: 'change',
  },
  props: {
    show: Boolean,
    hideCancel: Boolean,
    hidePreview: Boolean,
  },
  computed: {
    prefix: () => prefix,

    display: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
  },
  methods: {
    onClickCancel() {
      this.display = false;
    },
    onClickCreateNewProfile() {
      this.display = false;
      this.profileId = null;
      this.profileRoute();
    },
    onClickToProfiles() {
      this.display = false;
      this.$router.push({ name: ownerRouteId });
    },
  },
};
</script>
