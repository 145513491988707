<template>
  <OInputAbstract class="o-input-tags-input" v-bind="abstractProps">
    <OInputTagsAbstract
      create-new-keyword
      :tag-variant="tagVariant"
      :placeholder="placeholder"
      v-bind="abstractProps"
      v-model="model"
    />
  </OInputAbstract>
</template>

<script>
import OInputAbstract from '../abstract';
import OInputTagsAbstract from '../tags/abstract';

const prefix = 'o.input-tags-input';

export default {
  name: 'o-input-tags-input',
  mixins: [OInputAbstract],
  components: {
    OInputAbstract,
    OInputTagsAbstract,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: Array,
    placeholder: String,
    // @see tag variants
    tagVariant: String,
  },
  computed: {
    prefix: () => prefix,
    model: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      },
    },
  },
};
</script>
