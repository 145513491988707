<template>
  <BContainer class="v-profile-person mt-4">
    <form autocomplete="off">
      <BRow>
        <BCol sm="6">
          <BCard no-body class="h-100">
            <OCardHeader secondary>
              {{ $t('profile.applicant-contact') }}*
            </OCardHeader>
            <BCardBody>
              <CModelPerson stretch-columns :show-errors="showPopover"
                            v-model="person" @change="canSaveUpdate" />
              <CModelAddress allow-autofill :show-errors="showPopover" v-model="address" />
              <OInputValue
                type="email"
                identity="profile-input-email"
                :label="$t(`fields.email`)"
                :description="$t(`generic.correct-spelling`)"
                v-model="person.email"
              />
              <CModelContact
                phone-required
                :show-errors="showPopover"
                v-model="person"
              />
            </BCardBody>
          </BCard>
        </BCol>
        <BCol sm="6">
          <BCard no-body>
            <OCardHeader secondary>{{ $t('profile.applicant-basic-data') }}*</OCardHeader>
            <BCardBody>
              <OInputDate
                required
                identity="profile-input-date-of-birth"
                :label="$t('profile.date-of-birth')"
                :selectedDate="dateOfBirth"
                :max-date="new Date()"
                v-model="dateOfBirth"
                @change="canSaveUpdate"
              />

              <OInputValue
                required
                icon-name="anchor"
                identity="profile-input-place-of-birth"
                :label="$t('profile.place-of-birth')"
                v-model="birthPlace"
              />
              <OInputAbstract required :label="$t('fields.nationality')">
                <BInputGroup>
                  <BInputGroupText>
                    <SOIcon name="flag" size="sm" />
                  </BInputGroupText>
                  <BFormSelect
                    data-identity="profile-select-nationality"
                    :overlay="simplify"
                    :options="languageOptions"
                    v-model="nationality"
                  />
                </BInputGroup>
              </OInputAbstract>

              <OInputOption
                identity="profile-option-driver-license"
                :label="$t(`profile.driver-license-label`)"
                :options="$t(`generic.unknown-true-false.options`)"
                :overlay="simplify"
                v-model="driverLicence"
              />

              <OInputOption
                v-if="driverLicence"
                identity="profile-option-passenger-car"
                :label="$t(`profile.passenger-car-label`)"
                :options="$t(`generic.unknown-true-false.options`)"
                :overlay="simplify"
                v-model="passengerCar"
              />

              <OInputOption
                identity="profile-option-work-permit"
                :label="$t('profile.work-permit.label')"
                :options="$t('profile.work-permit.options')"
                :overlay="simplify"
                v-model="workPermit"
              />

              <OInputDate
                v-if="workPermit === 'requested'"
                identity="profile-input-applied-date"
                :overlay="simplify"
                :max-date="new Date()"
                v-model="appliedDate"
              />

              <OInputOption
                identity="profile-option-marital-status"
                :overlay="simplify"
                v-bind="maritalStatusProps"
                v-model="maritalStatus"
              />

              <OInputOption
                as="select"
                identity="profile-option-children"
                :overlay="simplify"
                v-bind="childrenProps"
                v-model="children"
              />

              <OInputOption
                v-if="showAssistanceSecured"
                identity="profile-option-assistance-secured"
                :label="$t('profile.assistance-secured')"
                :options="$t('generic.true-false.options')"
                :overlay="simplify"
                v-model="assistanceSecured"
              />
            </BCardBody>
          </BCard>
          <BCard no-body footer-tag="footer" class="mb-0" data-identitiy="person-informed-continue-card">
            <ODimmer v-if="!canSave" />
            <OCardHeader secondary>
              <CTrainclip url="https://player.vimeo.com/video/887357981?h=6b336bb664" />
              {{ $t('profile.informed.headline') }}*
            </OCardHeader>
            <BCardBody>
              <BFormCheckbox identity="profile-checkbox-informed" v-model="informed">
                <span class="label" v-html="informedLabel"></span>
                <span>
                  {{ $t('profile.informed.label-addition.before') }}
                  <a href="/privacy/obtaining" @click="onClickToForm">{{
                    $t('profile.informed.label-addition.link-label')
                  }}</a>
                  {{ $t('profile.informed.label-addition.after') }}
                </span>
              </BFormCheckbox>
            </BCardBody>
            <OCardHint>{{ $t('profile.informed.note') }}</OCardHint>
            <OProfileToolbarEditing
              slot="footer"
              back-routed
              back-route="direct-pv.profile.step-1"
              :can-open-preview="canSave"
              :profile-id="profileId"
              @submit="onSubmit"
            />
          </BCard>
        </BCol>
      </BRow>
    </form>
    <ODialogOops v-model="isNotInformed" />
    <ODialogIncomplete v-model="isSaved" :internal-id="internalId" />
    <OMarkRequiredLabel />
    <OSticky container-class="container">
      <OLayoutSwitch class="m-2" />
    </OSticky>
  </BContainer>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BCard,
  BCardBody,
  BInputGroup,
  BInputGroupText,
  BFormSelect,
  BFormCheckbox,
} from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { profileMixin } from '../../../tools/mixins/profile.mixin';
import { pageMixin } from 'library/components/src/plugins/page/mixin/page.mixin';
import { fullname } from 'library/components/src/tools/filters/fullname';
import { asOptions } from 'library/src/utilities/as-options';

import OCardHeader from '../../o/card/header';
import OCardHint from '../../o/card/hint';
import OInputAbstract from '../../o/input/abstract';
import OInputValue from '../../o/input/value';
import OInputDate from '../../o/input/date';
import OInputOption from '../../o/input/option';
import OProfileToolbarEditing from '../../o/profile/toolbar/editing';
import CModelAddress from '../../c/model/address';
import ODialogOops from '../../o/dialog/oops';
import ODialogIncomplete from '../../o/dialog/incomplete';
import CModelPerson from '../../c/model/person';
import CModelContact from '../../c/model/contact';
import OLayoutSwitch from '../../o/layout/switch';
import OSticky from '../../o/sticky';
import SOIcon from 'library/components/src/components/so/icon';
import { hasInterface } from 'library/src/utilities/has-interface';
import { empty } from 'library/src/utilities/types';
import ODimmer from '../../o/dimmer';
import OMarkRequiredLabel from '../../o/mark/required-label.vue';
import { simplifyMixin } from '../../../tools/mixins/simplify.mixin';
import CTrainclip from '../../c/trainclip';

const prefix = 'vw.profile-personal';
const notEmpty = v => !empty(v);

export default {
  mixins: [pageMixin, validationMixin, profileMixin, simplifyMixin],
  components: {
    OMarkRequiredLabel,
    BRow,
    BCol,
    BContainer,
    BCard,
    BCardBody,
    BInputGroup,
    BInputGroupText,
    BFormSelect,
    BFormCheckbox,
    ODimmer,
    OInputAbstract,
    CModelContact,
    CModelPerson,
    ODialogIncomplete,
    ODialogOops,
    CModelAddress,
    OInputOption,
    OInputDate,
    OInputValue,
    OCardHeader,
    OCardHint,
    OProfileToolbarEditing,
    SOIcon,
    OLayoutSwitch,
    OSticky,
    CTrainclip,
  },
  data() {
    return {
      criticalError: null,
      internalId: null,
      person: {},
      address: {},
      informed: null,
      dateOfBirth: null,
      children: '',
      birthPlace: null,
      nationality: null,
      workPermit: 'no-require',
      appliedDate: null,
      driverLicence: null,
      passengerCar: null,
      assistanceSecured: true,
      profileIdFromUrl: null,
      isNotInformed: false,
      isSaved: false,
      canSave: false,
      showPopover: false,
      maritalStatus: 'single',
    };
  },
  computed: {
    informedLabel() {
      return this.$t('profile.informed.label-template', {
        name: fullname(this.person, false, this.$t('profile.informed.name-not-set-placeholder')),
      });
    },
    showAssistanceSecured() {
      return ![null, 'unknown', 'none'].includes(this.children);
    },
    childrenProps() {
      return {
        label: this.$t('fields.children'),
        options: asOptions(this.$t('generic.children')),
      };
    },
    maritalStatusProps() {
      return {
        label: this.$t('fields.maritalstatus'),
        options: asOptions(this.$t('generic.marital-status')),
      };
    },
    languageOptions() {
      return asOptions(this.$t('generic.language'));
    },
    model() {
      return {
        person: { ...this.person },
        address: { ...this.address },
        personal: {
          // geburstort und datum
          dateOfBirth: this.dateOfBirth,
          birthPlace: this.birthPlace,

          nationality: this.nationality,

          // arbeitserlaubnis
          workPermit: this.workPermit,
          appliedDate: this.appliedDate,

          driverLicence: this.driverLicence,
          passengerCar: this.passengerCar,

          maritalStatus: this.maritalStatus,

          // kinder
          children: this.children,
          assistanceSecured: this.assistanceSecured,
        },
        // wurde er informiert?
        informed: this.informed,
      };
    },
  },
  validations: {
    children: {
      required,
    },
    dateOfBirth: {
      required,
    },
    nationality: {
      required,
    },
    birthPlace: {
      required,
    },
  },

  created() {
    this.$on('profile-loaded', profile => {
      if (profile) {
        const { person, address, personal, informed, internalId } = profile;
        this.internalId = internalId;
        Object.assign(
          this,
          {
            person: person || {},
            address: address || {},
          },
          personal,
        );
        this.informed = !!informed;
      }
    });
    this.$on('profile-not-exists', () => this.profileRoute());
  },

  beforeMount() {
    this.setPageParams({
      sectionGroup: this.$t(`vw.directPv.index.title`),
      sectionTitle: this.$t(`${prefix}.page.title`),
    });
  },

  methods: {
    onClickToForm(event) {
      event.preventDefault();
      this.$router.push({ name: 'privacy.obtaining' });
    },
    canSaveUpdate() {
      if (!this.dateOfBirth) {
        this.canSave = false;
      } else {
        this.canSave = hasInterface(this.person, {
          salutation: notEmpty,
          firstname: notEmpty,
          lastname: notEmpty,
        });
      }
    },

    async onSubmit(type) {
      this.criticalError = null;
      this.isNotInformed = !this.informed;
      if (this.isNotInformed) {
        return;
      }

      this.$v.$touch();

      this.$logger.info('Submit profile personal data', this.profileId, this.model);

      try {
        await this.$api.profile.savePersonalModel(this.profileId, this.model);
        this.profileSuccess();
      } catch (e) {
        // catch not validation error
        if (e.request) {
          if (e.request.status !== 400) {
            this.criticalError = e;
          }
        }

        console.log(e);
        this.profileError(e);
      }

      if (!this.criticalError) {
        this.profileRoute(type, 'direct-pv.profile.step-3');
      }
    },
  },
};
</script>
