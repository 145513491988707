<template>
  <div :class="className">
    <div class="dimmer-layer"></div>
    <div v-if="asContainer" class="inner">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'o-dimmer',
  computed: {
    className() {
      return [
        'o-dimmer',
        {
          'as-container': this.asContainer,
        },
      ];
    },
    asContainer() {
      return !!this.$slots.default;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../component';

.o-dimmer {
  > .dimmer-layer {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $color-dimmer;
    z-index: $index-dimmer;
  }

  &:not(.as-container) {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $color-dimmer;
    z-index: $index-dimmer;
  }

  &.as-container {
    position: relative;
  }
}
</style>
<style lang="scss">
@import '../../component';

// global parent
.has-dimmer {
  position: relative;
}
.add-overlay-dimmer {
  position: relative;
  z-index: $index-dimmer + 1;
}
</style>
