<template>
  <div class="c-model-career-professional group-container as-parent">
    <OInputGroupHeader no-index-label :index="index" :headline="headline" @remove="$emit('remove', { index })" />
    <OInputValue
      required
      autofocus
      identity="career-professional-input-company"
      :placeholder="$t(`${prefix}.company-placeholder`)"
      :description="$t(`${prefix}.company-description`)"
      v-model="title"
      @change="onChangeUpdateModel"
    />
    <OInputModelDatecareer
      required
      :label="$t('fields.date-range')"
      :description="$t('fields.date-range-description')"
      v-model="date"
      @change="onChangeUpdateModel"
    />
    <OInputOverlay :show="simplify">
      <OInputOption
        as="select"
        identity="career-professional-option-business"
        :label="$t('generic.business.label')"
        :options="businessOptions"
        v-model="business"
        @change="onChangeUpdateModel"
      />
      <div class="group-container as-child">
        <CModelCareerJob
          v-for="(job, jobIndex) in jobs"
          :key="`${index}-job-${jobIndex}`"
          :index="jobIndex"
          v-model="jobs[jobIndex]"
          @change="onChangeUpdateModel"
          @remove="onRemoveJob"
        />
        <SOAdd :label="addJobLabel" @click="onClickAddJob" />
      </div>
      <OInputOption
        as="select"
        identity="career-professional-option-event-of-default"
        :label="$t('generic.event-of-default.label')"
        :options="$t('generic.event-of-default.enum') | toOptions"
        v-model="eventOfDefault"
        @change="onChangeUpdateModel"
      />
      <OInputTextarea
        v-if="'others' === eventOfDefault"
        required
        identity="career-professional-option-event-of-default-additional"
        :label="$t('generic.event-of-default.additional-label')"
        :rows="2"
        v-model="eventOfDefaultDescription"
        @change="onChangeUpdateModel"
      />
    </OInputOverlay>
  </div>
</template>

<script>
import { toOptions } from 'library/components/src/tools/filters/to-options';
import { valueModelMixinFactory } from 'library/components/src/tools/mixins/value-model.mixin';

import SOAdd from 'library/components/src/components/so/add';
import OInputGroupHeader from '../../../o/input/group/header';
import OInputValue from '../../../o/input/value';
import OInputOption from '../../../o/input/option';
import OInputTextarea from '../../../o/input/textarea';
import CModelCareerJob from './job';
import OInputModelDatecareer from '../../../o/input/model/datecareer';
import OInputOverlay from '../../../o/input/overlay';

const prefix = 'c.model-career-professional';

const model = {
  title: null,
  date: null,
  business: null,
  eventOfDefault: '',
  eventOfDefaultDescription: null,
  jobs: null,
};

/**
 * @property title
 * @property business
 * @property eventOfDefault
 * @property eventOfDefaultDescription
 * @property jobs
 */
export default {
  name: 'c-model-career-professional',
  mixins: [valueModelMixinFactory(model)],
  components: {
    OInputModelDatecareer,
    CModelCareerJob,
    SOAdd,
    OInputTextarea,
    OInputGroupHeader,
    OInputValue,
    OInputOption,
    OInputOverlay,
  },
  filters: { toOptions },
  props: {
    index: {
      type: Number,
      required: true,
    },
    simplify: Boolean,
  },
  computed: {
    prefix: () => prefix,
    headline() {
      const { index, title } = this;

      const key = title ? 'headline-index-has-title' : 'headline-index';
      return this.$t(`${prefix}.${key}`, {
        index: index + 1,
        title,
      });
    },
    addJobLabel() {
      if (Array.isArray(this.jobs) && this.jobs.length > 0) {
        return this.$t(`${prefix}.add-job-label`);
      }

      return this.$t(`${prefix}.add-first-job-label`);
    },
    businessOptions() {
      return [{ text: this.$t('generic.not-set') }, ...toOptions(this.$t('generic.business.enum'))];
    },
  },
  methods: {
    onClickAddJob() {
      if (!Array.isArray(this.jobs)) {
        this.jobs = [];
      }

      this.jobs.push({});
    },
    onRemoveJob(event) {
      if (Array.isArray(this.jobs)) {
        this.jobs.splice(event.index, 1);
        this.onChangeUpdateModel();
      }
    },
  },
};
</script>
