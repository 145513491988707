<template>
  <div :class="className">
    <CTrainclip url="https://player.vimeo.com/video/905563248?h=d840932305" attached buttomEdge  />

    <div class="outliner" @click="onClickSwitch">
      <div class="pointer"></div>
    </div>
    <div class="labels" @click="onClickSwitch">
      <div class="label label-on">{{ onLabel }}</div>
      <div class="label label-off">{{ offLabel }}</div>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox, BBadge } from 'bootstrap-vue';
import CTrainclip from '../../c/trainclip';
import { simplifyMixin } from '../../../tools/mixins/simplify.mixin';

export default {
  name: 'o-layout-switch',
  mixins: [simplifyMixin],
  components: { BFormCheckbox, BBadge, CTrainclip },
  props: {
    onLabel: {
      type: String,
      default: 'Einfach',
    },
    offLabel: {
      type: String,
      default: 'Komplex',
    },
  },
  computed: {
    className() {
      return [
        'o-layout-switch', 
        this.simplify ? 'is-on' : 'is-off'
      ];
    },
  },
  methods: {
    onClickSwitch(event) {
      this.simplify = !this.simplify;
      this.$emit('simplify', this.simplify);
    },
  },
};
</script>

<style lang="scss">
@import '../../../component';

$size: 24px;
$space: 2px;
$radius: $size / 2;
$right-radius: $radius / 2;
$border-radius: $radius $right-radius $right-radius $radius;
$pointer-size: $size - $space * 2;
$box-height: 44px;

.o-layout-switch {
  display: inline-block;
  color: white;
  background-color: #606060;
  position: relative;
  border-radius: $border-radius;
  cursor: pointer;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.84);

  .outliner {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: $radius;
    width: $size;
    margin: $space;

    > .pointer {
      transition: transform ease 0.5s;
      transform: translate(0, 0);
      position: absolute;
      top: 0;
      left: 0;
      margin: $space;
      background-color: #464646;
      border-radius: 50%;
      width: $pointer-size;
      height: $pointer-size;
    }
  }

  .label {
    padding: $space-tiny $space-default;
    padding-left: 34px;
    border-radius: $border-radius;
    font-size: 10px;
    text-align: left;

    &-on {
      border-bottom: 1px solid rgba(0, 0, 0, 0.82);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &-off {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  // state
  &.is-on {
    .label-on {
      background-color: $color-ci;
    }
  }

  &.is-off {
    .label-off {
      background-color: $color-ci;
    }

    .outliner > .pointer {
      transform: translateY($box-height - $space * 2 - $pointer-size);
    }
  }
}

// inner sticky object
.o-sticky .o-layout-switch {
  margin-bottom: $space-default;
}

// page updates
#app.has-sticky .c-container-base {
  padding-bottom: to-rem($box-height) + $space-default;
}
</style>
