<template>
  <div :class="className" :title="label">
    *{{ label }}
  </div>
</template>
<script>
const prefix = 'o.mark-required';

export default {
  name: 'o-mark-required-label',
  computed: {
    className() {
      return 'o-mark-required-label m-1 font-xs color-grey';
    },
    /**
     * define the tooltip
     *
     * @returns {*}
     */
    label() {
      const { tooltip } = this;
      return tooltip ? tooltip : this.$t(`${prefix}.tooltip`);
    },
  },
};
</script>
