<script>
/**
 * load data from content api and show result
 */
import SOErrorMessage from 'library/components/src/components/so/error-message';

export default {
  name: 'o-content',
  props: {
    name: {
      type: String,
      required: true,
    },
    language: {
      type: String,
      default: 'de',
    },
    asInline: Boolean,
  },
  data() {
    return {
      innerHtml: null,
      error: null,
    };
  },
  computed: {
    className() {
      return [
        'o-content',
        'user-content',
        {
          'as-inline': this.asInline,
        },
      ];
    },
  },
  created() {
    this.$api.content
      .get(this.name, this.language)
      .then(content => (this.innerHtml = content.body))
      .catch(error => (this.error = error));
  },
  render(createElement) {
    const { innerHtml, error } = this;
    // show error
    if (error) {
      return createElement(SOErrorMessage, { props: { error } });
    }

    // show div
    return createElement('div', {
      class: this.className,
      domProps: {
        innerHTML: innerHtml,
      },
    });
  },
};
</script>
