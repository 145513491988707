<template>
  <div class="c-model-career-study group-container as-parent">
    <OInputGroupHeader no-index-label :index="index" :headline="headline" @remove="$emit('remove', { index })" />
    <OInputValue
      required
      autofocus
      :placeholder="$t(`${prefix}.name-placeholder`)"
      v-model="name"
      @change="onChangeUpdateModel"
    />
    <OInputModelDatecareer required :label="$t('fields.date-range')" v-model="date" @change="onChangeUpdateModel" />
    <OInputValue
      :label="$t(`${prefix}.location-label`)"
      :placeholder="$t(`${prefix}.location-placeholder`)"
      v-model="location"
      @change="onChangeUpdateModel"
    />
    <OInputValue
      :label="$t(`${prefix}.focus-label`)"
      :placeholder="$t(`${prefix}.focus-placeholder`)"
      v-model="focus"
      @change="onChangeUpdateModel"
    />

    <BFormCheckbox class="mt-2 mb-3" v-model="completed" @change="onChangeUpdateModel">
      {{ $t(`${prefix}.completed-label`) }}
    </BFormCheckbox>
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue';
import { toOptions } from 'library/components/src/tools/filters/to-options';
import { valueModelMixinFactory } from 'library/components/src/tools/mixins/value-model.mixin';
import OInputGroupHeader from '../../../o/input/group/header';
import OInputValue from '../../../o/input/value';
import OInputModelDatecareer from '../../../o/input/model/datecareer';

const prefix = 'c.model-career-study';

const model = {
  name: null,
  date: null,
  location: null,
  completed: null,
  focus: null,
  typeOfDegree: null,
};

/**
 */
export default {
  name: 'c-model-career-study',
  mixins: [valueModelMixinFactory(model)],
  components: {
    BFormCheckbox,
    OInputModelDatecareer,
    OInputValue,
    OInputGroupHeader,
  },
  filters: { toOptions },
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    prefix: () => prefix,
    headline() {
      const { index, name } = this;

      const key = name ? 'headline-index-has-title' : 'headline-index';
      return this.$t(`${prefix}.${key}`, {
        index: index + 1,
        name,
      });
    },
  },
};
</script>
