<template>
  <BCard class="c-model-position" no-body header-tag="header" footer-tag="footer">
    <OCardHeader secondary>{{ $t(`${prefix}.headline`) }}*</OCardHeader>
    <BCardBody>
      <OInputSelectPosition :max="3" v-model="positions" @change="pushModel" />
      <OInputModelKeyword
        :language="language"
        :description="$t(`${prefix}.keyword-description`)"
        :positions="positions"
        v-model="keywords"
        @change="pushModel"
      />
      <OInputExperience :overlay="experienceOverlay" v-model="experience" @change="pushModel" />
    </BCardBody>
    <slot name="footer" />
  </BCard>
</template>

<script>
import { languageMixin } from 'library/components/src/tools/mixins/language.mixin';
import { BCard, BCardBody } from 'bootstrap-vue';
import OInputSelectPosition from '../../o/input/select/position';
import OInputModelKeyword from '../../o/input/model/keyword';
import OCardHeader from '../../o/card/header';
import OInputExperience from '../../o/input/experience';

const prefix = 'c.model-position';

export default {
  name: 'c-model-position',
  mixins: [languageMixin],
  components: {
    OInputExperience,
    BCard,
    BCardBody,
    OInputSelectPosition,
    OInputModelKeyword,
    OCardHeader,
  },
  model: {
    prop: 'value',
    event: 'value',
  },
  data() {
    return {
      positions: [],
      keywords: [],
      experience: null,
    };
  },
  props: {
    value: Object,
    experienceOverlay: Boolean,
  },
  computed: {
    prefix: () => prefix,
  },
  watch: {
    value(v) {
      this.mapValue(v);
    },
  },
  beforeMount() {
    this.mapValue();
  },
  methods: {
    mapValue(value) {
      if (value) {
        const { positions, keywords } = value;
        this.experience = value.experience;

        if (Array.isArray(positions)) {
          this.positions = positions;
        }
        if (Array.isArray(keywords)) {
          this.keywords = keywords;
        }
      }
    },
    pushModel() {
      const { positions, keywords, experience } = this;
      this.$emit('value', { positions, keywords, experience });
    },
  },
};
</script>

<style scoped></style>
