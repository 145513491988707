<template>
  <nav class="o-profile-toolbar-editing">
    <template v-if="error">
      <SOErrorMessage :error="error" :translation-key="errorTranslationKey" />
    </template>

    <div class="text-right button-container">
      <BButton
        v-if="backRoute"
        type="button"
        variant="dark"
        data-identity="profile-toolbar-editing-back"
        :title="$t(`${prefix}.back-button`)"
        @click="onBack"
      >
        <SOIcon name="arrow-left" size="sm" />
      </BButton>

      <template v-if="canOpenPreview">
        <BButton
          variant="primary"
          data-identity="profile-toolbar-editing-preview"
          :title="$t(`${prefix}.preview-button`)"
          @click="onSubmitType('preview')"
        >
          <SOIcon name="eye" size="sm" />
        </BButton>
        <BButton
          variant="primary"
          data-identity="profile-toolbar-editing-overview"
          :title="$t(`${prefix}.return-button`)"
          @click="onSubmitType('return')"
        >
          <SOIcon name="users" size="sm" />
        </BButton>
      </template>

      <BButton
        variant="primary"
        :type="submitType"
        :disabled="isInvalid"
        :to="toNextRoute"
        :title="$t(`${prefix}.submit-button`)"
        data-identity="profile-toolbar-editing-submit"
        @click="onSubmit()"
      >
        <SOIcon name="arrow-right" size="sm" />
      </BButton>
    </div>
  </nav>
</template>
<script>
import { BButton } from 'bootstrap-vue';
import SOErrorMessage from 'library/components/src/components/so/error-message';
import SOIcon from 'library/components/src/components/so/icon';
import OProfileButtonPreview from '../../profile/button/preview';

const prefix = 'o.card-buttons';

export default {
  name: 'o-profile-toolbar-editing',
  components: { SOIcon, SOErrorMessage, OProfileButtonPreview, BButton },
  props: {
    profileId: String,
    isInvalid: Boolean,
    canOpenPreview: Boolean,
    error: Error,
    errorTranslationKey: String,
    submitType: {
      type: String,
      default: 'button',
    },
    backRoute: {
      type: [Object, String],
      default: null,
    },
    backRouted: Boolean,
    nextRoute: {
      type: [Object, String],
      default: null,
    },
  },
  computed: {
    prefix: () => prefix,
    toBackRoute() {
      if (this.backRoute) {
        return this.getRoute(this.backRoute);
      }
      return null;
    },
    toNextRoute() {
      if (this.nextRoute) {
        return this.getRoute(this.nextRoute);
      }
      return null;
    },
  },
  methods: {
    getRoute(to) {
      if (to) {
        const route = typeof to === 'string' ? { name: to } : Object.assign({}, to);
        route.params = Object.assign({}, route.params, { id: this.profileId });
        return route;
      }
      return null;
    },
    onSubmit($event) {
      this.$emit('submit', $event);
    },
    onBack() {
      const { toBackRoute } = this;
      if (this.backRouted) {
        return this.$router.push(toBackRoute);
      }

      this.$emit('back', toBackRoute);
    },
    onSubmitType(type) {
      this.$emit('submit', type);
    },
  },
};
</script>
