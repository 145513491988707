<template>
  <OInputAbstract class="o-input-model-datecareer" v-bind="fieldProps">
    <BInputGroup>
      <OInputValue
        class="input-item"
        pattern="date-flex"
        error-event="error"
        identity="datecareer-input-from"
        :required="required"
        :placeholder="$t(`${prefix}.from-placeholder`)"
        v-model="from"
        @error="onInputError"
        @change="onChangeUpdateModel"
      />

      <BInputGroupText class="delimiter font-weight-bold">
        {{ $t(`${prefix}.delimiter`) }}
      </BInputGroupText>

      <BFormGroup v-if="toType === TYPES.YET" class="input-item">
        <div class="form-control">{{ $t(`${prefix}.to-yet`) }}</div>
      </BFormGroup>
      <OInputValue
        v-else-if="toType === TYPES.CUSTOM"
        autofocus
        class="input-item"
        pattern="date-flex"
        error-event="error"
        identity="datecareer-input-to"
        :required="required"
        :disabled="!from"
        :placeholder="$t(`${prefix}.to-placeholder`)"
        v-model="to"
        @error="onInputError"
        @change="onChangeUpdateModel"
      />
      <BFormGroup v-else class="input-item">
        <div class="form-control">{{ $t(`${prefix}.to-not-net`) }}</div>
      </BFormGroup>

      <BDropdown variant="light" :disabled="!from">
        <template v-slot:button-content></template>
        <BDropdownItem @click="onClickSetType(TYPES.NONE)">{{ $t(`${prefix}.to-not-net`) }}</BDropdownItem>
        <BDropdownItem @click="onClickSetType(TYPES.YET)">{{ $t(`${prefix}.to-yet`) }}</BDropdownItem>
        <BDropdownItem @click="onClickSetType(TYPES.CUSTOM)">{{ $t(`${prefix}.to-custom`) }}</BDropdownItem>
      </BDropdown>
    </BInputGroup>
  </OInputAbstract>
</template>

<script>
import { BInputGroup, BFormGroup, BDropdown, BDropdownItem } from 'bootstrap-vue';
import { valueModelMixinFactory } from 'library/components/src/tools/mixins/value-model.mixin';
import OInputAbstract from '../abstract';
import OInputValue from '../value';
import SOIcon from 'library/components/src/components/so/icon';

const prefix = 'o.input-model-datecareer';

const TYPES = {
  NONE: undefined,
  YET: 'yet',
  CUSTOM: 'custom',
};

const model = {
  from: null,
  to: null,
  toType: TYPES.CUSTOM,
};

/**
 * @property from
 * @property to
 */
export default {
  name: 'o-input-model-datecareer',
  mixins: [OInputAbstract, valueModelMixinFactory(model)],
  components: {
    BInputGroup,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    OInputAbstract,
    SOIcon,
    OInputValue,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      inputError: null,
    };
  },
  props: {
    required: Boolean,
  },
  computed: {
    prefix: () => prefix,
    TYPES: () => TYPES,
    fieldProps() {
      const { inputError } = this;
      const props = this.abstractProps;
      props.error = inputError;
      return props;
    },
  },

  methods: {
    onInputError(message) {
      this.inputError = message;
    },
    onClickSetType(value) {
      this.toType = value;

      // clear custom
      if (value !== TYPES.CUSTOM) {
        this.to = undefined;
      }

      //  update params
      this.onChangeUpdateModel();
    },
  },
};
</script>
<style lang="scss" scoped>
.delimiter {
  background-color: transparent;
  border: {
    left: none;
    right: none;
  }
}
.input-item {
  margin-bottom: 0;
  flex-grow: 1;
}
</style>

<style lang="scss">
.o-input-model-datecareer {
  .input-item > .form-group {
    margin-bottom: 0;
  }
}
</style>
