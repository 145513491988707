<template>
  <BContainer class="v-profile-commission mt-4">
    <BRow>
      <BCol md="6">
        <BCard no-body class="h-100" footer-tag="footer">
          <ODimmer v-if="!rights.allowChange" />
          <OCardHeader secondary>
            {{ $t('profile.commission.headline') }}*
            <template v-slot:append>
              <CTrainclip v-slot="append" url="https://player.vimeo.com/video/840076471" />
            </template>
          </OCardHeader>
          <BCardBody>
            <BFormGroup
              :label="$t('profile.commission.how-much-commission')"
              :description="$t('profile.commission.choose-a-value')"
              label-class="font-weight-bold"
            >
              <BFormSelect
                class="limited-width"
                data-identity="commission-select-commission"
                :state="$v.commission | validationState"
                :disabled="!rights.allowChange"
                v-model="commission"
              >
                <option :value="null">
                  {{ $t('profile.commission.please-select') }}
                </option>
                <option v-for="percent in commissionInPercent" :value="percent" :key="percent">{{ percent }}%</option>
              </BFormSelect>
              <BFormInvalidFeedback>
                {{ $t('validation-errors.commission') }}
              </BFormInvalidFeedback>
            </BFormGroup>
            <BFormGroup :label="$t('profile.commission.choose-possible-interval')" label-class="font-weight-bold">
              <BFormCheckboxGroup stacked :disabled="!rights.allowChange" v-model="intervals">
                <BFormCheckbox
                  v-for="key in commissionIntervals"
                  :data-identity="`commission-checkbox-commission-${key}`"
                  :value="key"
                  :key="key"
                  class="mt-2"
                >
                  {{ $t(`intervals.${key}`) }}
                </BFormCheckbox>
              </BFormCheckboxGroup>
            </BFormGroup>
          </BCardBody>
          <OCardHint>
            <p>{{ $t('profile.commission.choose-possible-interval-description') }}</p>
            {{ $t('profile.commission.choose-possible-interval-description-additional') }}
          </OCardHint>
        </BCard>
      </BCol>
      <BCol md="6">
        <BCard no-body class="h-100" footer-tag="footer">
          <ODimmer v-if="!rights.allowChange" />
          <OCardHeader data-identity="profile-commission-header" secondary>{{ calulatorTitle }}</OCardHeader>
          <BCardBody>
            <OProfileCalculator :commission="commission" />
          </BCardBody>
          <BCardFooter class="add-overlay-dimmer">
            <OProfileToolbarEditing :can-open-preview="hasProfileValidSchema" @submit="onSubmit" />
          </BCardFooter>
        </BCard>
      </BCol>
    </BRow>
    <OMarkRequiredLabel />
  </BContainer>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardFooter,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormInvalidFeedback,
  BFormSelect,
} from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import { profileMixin } from '../../../tools/mixins/profile.mixin';
import { serviceMixin } from 'library/components/src/tools/mixins/service.mixin';
import { pageMixin } from 'library/components/src/plugins/page/mixin/page.mixin';

import { validationState } from 'library/components/src/tools/filters/validation-state';

import OCardHeader from '../../o/card/header';
import ODimmer from '../../o/dimmer';
import SOIcon from 'library/components/src/components/so/icon';
import OProfileCalculator from '../../o/profile/calculator';
import CTrainclip from '../../c/trainclip';
import OProfileToolbarEditing from '../../o/profile/toolbar/editing';
import OCardHint from '../../o/card/hint';
import OMarkRequiredLabel from '../../o/mark/required-label.vue';
import { simplifyMixin } from '../../../tools/mixins/simplify.mixin';

const prefix = 'vw.profile-commission';

export default {
  mixins: [pageMixin, validationMixin, profileMixin, serviceMixin, simplifyMixin],
  filters: {
    validationState,
  },
  components: {
    OMarkRequiredLabel,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardFooter,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormInvalidFeedback,
    BFormSelect,
    OCardHint,
    SOIcon,
    ODimmer,
    OProfileCalculator,
    OCardHeader,
    OProfileToolbarEditing,
    CTrainclip,
  },
  data() {
    return {
      commission: null,
      intervals: [],
      commissionInPercent: [],
      commissionIntervals: {},
      rights: {
        allowChange: true,
      },
    };
  },
  computed: {
    prefix: () => prefix,
    calulatorTitle() {
      const { commission } = this;
      if (commission > 0) {
        return this.$t('profile.commission.distribution-of-sales-commission', {
          commission,
        });
      }
      return this.$t('profile.commission.distribution-of-sales');
    },
  },
  validations: {
    commission: {
      required,
    },
    intervals: {
      required,
      minLength: minLength(1),
    },
  },

  // hooks
  created() {
    this.$on('profile-loaded', profile => {
      if (profile) {
        const { intervals, commission } = profile.provision;
        this.intervals = intervals;
        this.commission = commission;
        this.rights = profile.rights;
      } else {
        this.intervals = [];
        this.commission = null;
        this.rights = {};
      }
    });

    this.dispatchLoading(prefix, () =>
      this.$api.profile.commission().then(configuration => {
        const { commissionInPercent, commissionIntervals } = configuration;
        this.commissionIntervals = commissionIntervals;
        this.commissionInPercent = commissionInPercent;
      }),
    );
  },

  beforeMount() {
    this.setPageParams({
      sectionGroup: this.$t(`vw.directPv.index.title`),
      sectionTitle: this.$t(`${prefix}.page.title`),
    });
  },

  methods: {
    async onSubmit(type) {
      const { commission, intervals } = this;
      const provisionModel = { commission, intervals: [...intervals] };

      // update
      if (this.hasProfile && this.rights.allowChange) {
        // update event
        try {
          this.$v.$touch();
          const valid = !this.$v.invalid;

          // logic here
          if (valid) {
            await this.$api.profile.provision(this.profileId, provisionModel);
            return this.profileRoute(type, 'direct-pv.profile.step-2');
          }
        } catch (error) {
          this.profileError(error, {
            useValidation: true,
            translationKey: `${prefix}.errors`,
          });
        }
      } else if (this.hasProfile) {
        return this.profileRoute(type, 'direct-pv.profile.step-2');
      }

      // create
      else {
        this.$v.$touch();
        const valid = !this.$v.invalid;

        // logic here
        if (valid) {
          this.$logger.info('Submit profile', provisionModel);

          try {
            const profileModel = await this.$api.profile.creating(provisionModel);
            this.profileId = profileModel.id;
            this.profileSuccess();

            this.profileRoute(type, 'direct-pv.profile.step-2');
          } catch (error) {
            this.profileError(error, {
              useValidation: true,
              translationKey: `${prefix}.errors`,
            });
          }
        }
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
