<template>
  <BContainer class="v-profile-upload mt-4">
    <BCardGroup deck>
      <!-- upload and testimonies -->
      <BCard data-section="upload" no-body>
        <OCardHeader secondary>{{ $t('profile.upload-data') }}</OCardHeader>

        <!-- profile image -->
        <OInputOverlay :show="simplify">
          <BCardBody class="no-flex-grow">
            <OInputUpload
              accept-type="image"
              :label="$t('profile.upload-picture')"
              :description="$t('profile.formats-jpeg-png')"
              v-model="picture"
            />
          </BCardBody>
        </OInputOverlay>

        <OCardHeader is-inside-card secondary>{{ $t('profile.document-headline') }}</OCardHeader>

        <BCardBody>
          <BFormGroup v-for="(document, index) in documents" :key="index">
            <OInputOverlay :show="index === 0 ? false : simplify">
              <OInputGroupHeader
                no-index-label
                :index="index"
                :headline="$t('profile.document-index', { index: index + 1 })"
                @remove="onRemoveDocument"
              />
              <OInputValue
                required
                autofocus
                :placeholder="$t('profile.document-placeholder')"
                v-model="document.name"
              />
              <OInputUpload
                :description="$t('profile.formats-pdf-jpeg')"
                v-model="document.file"
                accept-type="document"
              />
            </OInputOverlay>
          </BFormGroup>

          <OInputOverlay>
            <SOAdd @click="onAddDocument" />
          </OInputOverlay>

          <SOAdd v-if="!documents.length" class="mt-1" label="Vita hochladen" @click="onAddVita" />
        </BCardBody>
      </BCard>

      <!-- education/school -->
      <BCard no-body data-section="education">
        <OCardHeader secondary>{{ $t('profile.education') }}</OCardHeader>
        <BCardBody>
          <OInputOverlay :show="simplify">
            <CModelCareerSchool
              v-for="(type, index) in schools"
              :key="`school-${index}`"
              :index="index"
              v-model="schools[index]"
              @remove="onRemoveSchool"
            />
            <SOAdd @click="onAddSchool" />
          </OInputOverlay>
        </BCardBody>
      </BCard>
    </BCardGroup>

    <BCardGroup deck class="mt-4">
      <!-- nativeLanguage -->
      <BCard no-body header-tag="header" data-section="language">
        <OCardHeader secondary>{{ $t('profile.linguistic-proficiency') }}*</OCardHeader>

        <BCardBody class="no-flex-grow">
          <BInputGroup>
            <BFormSelect
              required
              autofocus
              data-identity="profile-select-native-language"
              :placeholder="$t('profile.native-language')"
              :options="languageOptions"
              v-model="nativeLanguage"
            />
            <OMarkRequired variant="append" />
          </BInputGroup>
        </BCardBody>

        <!-- foreign-languages -->
        <OCardHeader is-inside-card secondary>{{ $t('profile.foreign-languages') }}</OCardHeader>

        <BCardBody>
          <OInputOverlay :show="simplify">
            <BFormGroup v-for="(lang, index) in languages" :key="index" class="group-container as-parent">
              <OInputGroupHeader
                no-index-label
                :index="index"
                :headline="$t('profile.foreign-language-index', { index: index + 1 })"
                @remove="onRemoveLanguage"
              />
              <BInputGroup>
                <BFormSelect
                  required
                  autofocus
                  :placeholder="$t('profile.foreign-language-placeholder')"
                  v-model="lang.language"
                  :options="languageOptions"
                />
                <OMarkRequired variant="append" />
              </BInputGroup>
              <BFormRadioGroup class="mt-3" v-model="lang.level" :options="levelOption" checked="negotiable" stacked />
            </BFormGroup>
            <SOAdd @click="onAddLanguages" />
          </OInputOverlay>
        </BCardBody>
      </BCard>

      <!-- studies -->
      <BCard no-body data-section="studies">
        <OCardHeader secondary>{{ $t('profile.training') }}</OCardHeader>
        <BCardBody>
          <OInputOverlay :show="simplify">
            <CModelCareerStudy
              v-for="(study, index) in studies"
              :key="`studies-${index}`"
              :index="index"
              v-model="studies[index]"
              @remove="onRemoveStudies"
            />
            <SOAdd @click="onAddStudies" />
          </OInputOverlay>
        </BCardBody>

        <OProfileToolbarEditing
          slot="footer"
          back-route="direct-pv.profile.step-4"
          can-open-preview
          :profile-id="profileId"
          @back="onBack"
          @submit="onSubmit"
        />
      </BCard>
    </BCardGroup>
    <OMarkRequiredLabel />
    <OSticky container-class="container">
      <OLayoutSwitch class="m-2" />
    </OSticky>
  </BContainer>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { profileMixin } from '../../../tools/mixins/profile.mixin';
import { serviceMixin } from 'library/components/src/tools/mixins/service.mixin';
import { pageMixin } from 'library/components/src/plugins/page/mixin/page.mixin';
import { onlyProps } from 'library/src/utilities/only-props';
import { asOptions } from 'library/src/utilities/as-options';

import {
  BContainer,
  BCard,
  BCardGroup,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BInputGroup,
  BFormSelect,
  BFormRadioGroup,
} from 'bootstrap-vue';
import SOIcon from 'library/components/src/components/so/icon';
import SOAdd from 'library/components/src/components/so/add';
import OCardHeader from '../../o/card/header';
import OInputAbstract from '../../o/input/abstract';
import OInputUpload from '../../o/input/upload';
import OInputValue from '../../o/input/value';
import OInputDate from '../../o/input/date';
import OProfileToolbarEditing from '../../o/profile/toolbar/editing';
import OMarkRequired from '../../o/mark/required';
import OInputGroupHeader from '../../o/input/group/header';
import CModelCareerSchool from '../../c/model/career/school';
import CModelCareerStudy from '../../c/model/career/study';
import { delay } from 'library/src/utilities/delay';
import OLayoutSwitch from '../../o/layout/switch';
import OSticky from '../../o/sticky';
import OInputOverlay from '../../o/input/overlay';
import OMarkRequiredLabel from '../../o/mark/required-label';
import { simplifyMixin } from '../../../tools/mixins/simplify.mixin';

const MODEL_PROPS = ['picture', 'documents', 'nativeLanguage', 'languages', 'schools', 'studies'];
const prefix = 'vw.profile-upload';

export default {
  mixins: [pageMixin, validationMixin, profileMixin, serviceMixin, simplifyMixin],
  components: {
    OMarkRequiredLabel,
    OInputOverlay,
    CModelCareerStudy,
    CModelCareerSchool,
    OInputGroupHeader,
    OMarkRequired,
    SOAdd,
    OInputDate,
    OInputAbstract,
    OInputUpload,
    OInputValue,
    OCardHeader,
    OProfileToolbarEditing,
    SOIcon,
    BContainer,
    BCard,
    BCardGroup,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BInputGroup,
    BFormSelect,
    BFormRadioGroup,
    OLayoutSwitch,
    OSticky,
  },
  data() {
    return {
      picture: null,
      documents: [],
      nativeLanguage: null,
      languages: [],
      schools: [],
      studies: [],
    };
  },
  computed: {
    model() {
      return onlyProps(this, MODEL_PROPS);
    },
    levelOption() {
      return asOptions(this.$t(`generic.language-level`));
    },
    languageOptions() {
      return asOptions(this.$t('generic.language'));
    },
  },
  validations: {
    nativeLanguage: {
      required,
    },
    picture: {
      required,
    },
    testimony: {
      required,
    },
  },

  // hooks
  created() {
    this.$on('profile-loaded', profile => {
      if (profile) {
        Object.assign(this, onlyProps(profile.education, MODEL_PROPS));
      }
    });
    this.$on('profile-not-exists', () => this.profileRoute());
  },

  beforeMount() {
    this.setPageParams({
      sectionGroup: this.$t(`vw.directPv.index.title`),
      sectionTitle: this.$t(`${prefix}.page.title`),
    });
  },

  // methods
  methods: {
    /**
     * on submit process
     **/
    async onSubmit(type) {
      await this.saveProfile().finally(() => this.profileRoute(type, 'direct-pv.profile.step-6'));
    },
    /**
     * on back process
     *
     * @param backRoute
     * @return {Promise<R>}
     */
    async onBack(backRoute) {
      return this.saveProfile().finally(() => this.$router.push(backRoute));
    },

    /**
     * send data to backend
     *
     * @return {Promise<boolean|Error>}
     */
    async saveProfile() {
      this.$v.$touch();
      await delay(250); // to save all model data

      return await this.dispatchLoading(prefix, () => this.$api.profile.saveEducation(this.profileId, this.model), {
        rejectable: true,
        error: {
          translationKey: `profile.validation-errors`,
        },
      });
    },

    onAddLanguages() {
      this.languages.push({
        language: null,
        level: 'negotiable',
      });
    },
    onRemoveLanguage(index) {
      this.languages.splice(index, 1);
    },
    onAddStudies() {
      this.studies.push({});
    },
    onRemoveStudies({ index }) {
      this.studies.splice(index, 1);
    },
    onAddSchool() {
      this.schools.push({});
    },
    onRemoveSchool({ index }) {
      this.schools.splice(index, 1);
    },
    onAddDocument() {
      this.documents.push({});
    },
    onAddVita() {
      this.documents.push({
        name: 'Vita',
      });
    },
    onRemoveDocument(index) {
      this.documents.splice(index, 1);
    },
  },
};
</script>
